import React from "react"
import * as theme from "../../theme"
import { BreadCrumbs, Page, SectionHeader } from "../../components/page"
import headerImage from "./section-header.jpg"
import Layout from "../../layout"
import { LinkButton } from "../../components/button"
import styled from "@emotion/styled"
import whatIsPilgrimage from "./what-is-pilgrimage.svg"
import medievalPilgrimage from "./medieval-pilgrimage.svg"
import chaucer from "./chaucer.svg"
import pilgrimageToday from "./pilgrimage-today.svg"
import { ActivityBox } from '../../components/boxes'
import worksheets from './worksheets.svg';
import { withPrefix } from "gatsby"

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 0.1em solid #efe7e0;
  padding: 0 0 5rem;

  &:first-child {
    margin-top: 3rem;
  }

  @media(max-width: 40rem) {
    flex-direction: column;

    div {
      ${props => (props.alternate ? "order: 3;" : "")}
    }
  }
  
  h2 {
    font-size: 1.7em;
  }

  p {
    line-height: 1.6;
  }

  a {
    margin-top: 1em;
  }

  img {
    max-height: 20rem;
    width: 30%;
    ${props => (props.alternate ? "order: 2;" : "")}
  }
  div {
    padding: 0 2rem;
    max-width: 25rem;
  }
`

const Pilgrimage = () => {
  return (
    <Layout title="Pilgrimage">
      <SectionHeader color={theme.colors.red.dark} image={headerImage}>
        Pilgrimage
      </SectionHeader>
      <BreadCrumbs items={[
        { name: 'Pilgrimage' }
      ]} />
      <Page>
      <Item>
        <img src={whatIsPilgrimage} alt="Pilgrim's Book" />
        <div>
          <h2>What is pilgrimage?</h2>
          <p>
          A pilgrimage is a spiritual journey to a holy place or shrine and these journeys have formed a part of many of the major world religions since ancient times. Canterbury Cathedral has been a focus for pilgrims for many centuries and continues to draw pilgrims today.
          </p>
          <LinkButton to="/pilgrimage/what-is-pilgrimage">
            Find out more
          </LinkButton>
        </div>
      </Item>
      <Item alternate>
        <img src={medievalPilgrimage} alt="Medieval Pilgrims" />
        <div>
          <h2>Medieval Pilgrimage</h2>
          <p>
          There was an expectation that people in medieval England would go on a pilgrimage at least once in their lives. Medieval Christendom (the Christian world) was full of Holy Places from Jerusalem and Rome to Canterbury and the shrine of St Thomas Becket. In the past, people sometimes went on pilgrimage when they or someone close to them was sick and hoping to find a cure. They may have also visited holy places as an act of penance, to ask forgiveness for committing a sin, or were even told to go on pilgrimage in order to be forgiven for a sin they had committed.
          </p>
          <LinkButton to="/pilgrimage/medieval-pilgrimage">
            Find out more
          </LinkButton>
        </div>
      </Item>
      <Item>
        <img src={chaucer} alt="Books and a quill" />
        <div>
          <h2>Chaucer</h2>
          <p>
          The Canterbury Tales is the title of one of the most famous books ever written. The author was Geoffrey Chaucer who told his story in Middle English, the language used by ordinary people at the time. Started in 1387, this book tells the story of a group of pilgrims travelling from London to Canterbury Cathedral to visit the shrine of Thomas Becket.
          </p>
          <LinkButton to="/pilgrimage/chaucer">Find out more</LinkButton>
        </div>
      </Item>
      <Item alternate>
        <img src={pilgrimageToday} alt="Candle" />
        <div>
          <h2>Pilgrimage Today</h2>
          <p>
          In recent times, pilgrimage has again become very popular. People from all faiths and none are drawn to many different pilgrimage sites and journey along ancient routes to arrive at their chosen destination. Perhaps this is a reaction to the increasingly busy lives that people live, and the need to take some time away to reflect and experience something new.
          </p>
          <LinkButton to="/pilgrimage/pilgrimage-today">
            Find out more
          </LinkButton>
        </div>
      </Item>
      <h2>Activities</h2>
      <ActivityBox
        title="Pilgrimage activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/Pilgrimage.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘Pilgrimage’ resource.
      </ActivityBox>
      </Page>
    </Layout>
  )
}

export default Pilgrimage;